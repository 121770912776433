export interface IItem {
  itemCode: string
  name: string
  description: string
  color: string
  defaultColor: boolean
  section: string
  size: string
  composition: string
  weight: string
  fabricType: string
  pattern: {
    type: string
    patterns?: Array<string>
  }
  feeling: Array<string>
  appearance: Array<string>
  item: Array<string>
  function: Array<string>
  sustainable: Array<string>
  thumbnail: string
  deliveryStatus: 'PREPARATION' | 'AVAILABLE'
  fabricFile: {
    u3ma?: string
    zfab?: string
    texture?: string
  }
  purchasePrice: {
    fabric: number | null,
    texture: number | null
  } | null
  purchaseStatus: {
    fabric: 'purchased' | 'not_purchased' | null,
    texture: 'purchased' | 'not_purchased' | null
  } | null
  isAdditionalColor: {
    fabric: boolean | null,
    texture: boolean | null
  } | null
}

export interface IItemTable {
  itemCode: string
  color: string
  section: string
  name: string
  deliveryStatus: 'PREPARATION' | 'AVAILABLE'
  fabricFile: {
    u3ma?: string
    zfab?: string
    texture?: string
  }
}

export const compositionDetail = [
  {
    composition: '天然繊維',
    code: 'CO',
    detail: ['綿']
  },
  {
    code: 'LI',
    detail: ['リネン']
  },
  {
    code: 'RA',
    detail: ['ラミー']
  },
  {
    code: 'HEMP',
    detail: ['ヘンプ']
  },
  {
    code: 'JU',
    detail: ['ジュート']
  },
  {
    code: 'KE',
    detail: ['ケナフ']
  },
  {
    code: 'KP',
    detail: ['カポック']
  },
  {
    code: 'BA',
    detail: ['竹繊維（バンブー）']
  },
  {
    code: 'SE',
    detail: ['シルク']
  },
  {
    code: 'WO',
    detail: ['ウール']
  },
  {
    code: 'WV',
    detail: ['ヴァージンウール']
  },
  {
    code: 'WP',
    detail: ['アルパカ']
  },
  {
    code: 'WA',
    detail: ['アンゴラ']
  },
  {
    code: 'WS',
    detail: ['カシミヤ']
  },
  {
    code: 'WM',
    detail: ['モヘヤ']
  },
  {
    code: 'WK',
    detail: ['キャメル']
  },
  {
    code: 'WL',
    detail: ['リャマ']
  },
  {
    code: 'WG',
    detail: ['ビキューナ']
  },
  {
    code: 'WY',
    detail: ['ヤク']
  },
  {
    code: 'WU',
    detail: ['グアナコ（南米産のらくだに近い種）']
  },
  {
    composition: '化学繊維',
    code: 'R',
    detail: ['レーヨン']
  },
  {
    code: 'VI',
    detail: ['ビスコース']
  },
  {
    code: 'PG',
    detail: ['ポリノジック']
  },
  {
    code: 'MD',
    detail: ['モダール']
  },
  {
    code: 'CU',
    detail: ['キュプラ']
  },
  {
    code: 'BB',
    detail: ['ベンベルグ']
  },
  {
    code: 'TEN',
    detail: ['テンセル']
  },
  {
    code: 'LYO',
    detail: ['リヨセル']
  },
  {
    code: 'RC',
    detail: ['レクセル']
  },
  {
    code: 'TA',
    detail: ['トリアセテート']
  },
  {
    code: 'ACE',
    detail: ['アセテート']
  },
  {
    code: 'PL',
    detail: ['ポリエステル']
  },
  {
    code: 'PA',
    detail: ['ナイロン']
  },
  {
    code: 'PC',
    detail: ['アクリル']
  },
  {
    code: 'MA',
    detail: ['モダクリル（染色性に優れたアクリル）']
  },
  {
    code: 'PU',
    detail: ['ポリウレタン', 'エラスタン（弾性糸）']
  },
  {
    code: 'EL',
    detail: ['ライクラ']
  },
  {
    code: 'PE',
    detail: ['ポリエチレン']
  },
  {
    code: 'PP',
    detail: ['ポリプロピレン']
  },
  {
    code: 'CL',
    detail: ['ポリ塩化ビニル']
  },
  {
    code: 'PI',
    detail: ['分類外繊維（紙）']
  },
  {
    code: 'PR',
    detail: ['プロテイン']
  },
  {
    code: 'CF',
    detail: ['炭素繊維']
  },
  {
    code: 'ME',
    detail: ['メタルヤーン']
  },
  {
    code: 'PLA',
    detail: ['ポリ乳酸']
  },
  {
    composition: '他',
    code: 'AF',
    detail: ['その他（不明時、5％未満）']
  }
]
