import Vue from 'vue'
import VueMeta from 'vue-meta'
import InstantSearch from 'vue-instantsearch'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { NavigationGuardNext, Route } from 'vue-router'
import { Auth0Plugin, getInstance } from '@/assets/auth0'

import App from './App.vue'
import router from './router'
import store from './store'

import eventbus from '@/assets/common/eventbus'
import authConfig from '@/assets/consistants/authConfig'

import '@/assets/common/Filter'

import '@/assets/variables/common.scss'

import customAxios, { getInstance as getCustomeAxiosInstance } from '@/assets/axios'
import { jwtInterceptor } from '@/assets/axios/jwtInterceptor'

Vue.config.productionTip = false

Vue.use(eventbus)

Vue.use(VueMeta)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(InstantSearch as any)

Vue.use(
  Auth0Plugin,
  {
    onRedirectCallback: (appState: any) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : '/'
      )
    },
    ...authConfig
  }
)

Vue.use(customAxios)

jwtInterceptor(
  getCustomeAxiosInstance()
)

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  if (!to.meta?.protect) {
    return next()
  }

  const auth: any = getInstance()

  const verify = () => {
    if (auth.$data.isAuthenticated) {
      return next()
    }
    auth.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  if (!auth.$data.loading) {
    return verify()
  }
  auth.$watch('loading', (loading: boolean) => {
    if (!loading) {
      return verify()
    }
  })
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
