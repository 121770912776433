

















import Vue from 'vue'
import { Snackbar, SnackbarProps } from '@/assets/interfaces/component/Snackbar'

interface Data {
  countdown: number
  val: Snackbar
}

export default Vue.extend({
  name: 'Snackbar',
  model: {
    prop: 'bindValue',
    event: 'onChange'
  },
  props: {
    bindValue: {
      type: Boolean,
      default: false
    }
  },
  data() : Data {
    return {
      countdown: 0,
      val: {
        show: false,
        display: '',
        timeout: 2,
        color: '',
        zIndex: 1060
      }
    }
  },
  computed: {
    zIndex() : any {
      if (!this.val.zIndex) {
        return {}
      }
      return {
        'z-index': this.val.zIndex
      }
    }
  },
  watch: {
    'val.show'() {
      if (this.val.show) {
        this.countdown = this.val.timeout
        this.$eventbus.$emit('onSnackbarChange', false)
      }
    }
  },
  methods: {
    countDownChanged(dismissCountDown: any) {
      this.countdown = dismissCountDown
    },
    onDismissed() {
      this.countdown = 0
      this.val.show = false
    }
  },
  mounted() {
    this.$eventbus.$on('showSnackbar', (val: SnackbarProps) => {
      this.val.display = val.display
      this.val.show = val.show
      this.val.color = val.color
    })
  },
  destroyed() {
    this.$eventbus.$off('showSnackbar')
  }
})
