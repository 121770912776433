





























































import Vue from 'vue'
import Avatar from 'vue-avatar'

export default Vue.extend({
  name: 'Navbar',
  components: {
    Avatar
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout()
    }
  }
})
