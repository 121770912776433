






















































































import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { NavigationGuardNext, Route } from 'vue-router'
import { IItem } from '@/assets/interfaces/Item'
import { SnackbarProps } from '@/assets/interfaces/component/Snackbar'
import CompositionTable from '@/components/item/detail/CompositionTable.vue'
import FabricDetail from '@/components/item/detail/FabricDetail.vue'
import apiConfig from '@/assets/consistants/apiConfig'

interface Data {
  item: IItem & {
    assetRemaining: number
    assetAmount: number
    billingPlan: string
  }
  messages: {
    errors: {
      unknown: string
      forbidden: string
      token: string
    }
  }
  comsitionTableExpanded: boolean
  prevRoute?: Route
}

export default Vue.extend({
  name: 'ItemDetail',
  components: {
    CompositionTable,
    FabricDetail
  },
  data(): Data {
    return {
      item: {
        itemCode: '',
        name: '',
        description: '',
        composition: '',
        color: '',
        defaultColor: false,
        section: '',
        size: '',
        weight: '',
        fabricType: '',
        pattern: {
          type: ''
        },
        feeling: [],
        appearance: [],
        item: [],
        function: [],
        sustainable: [],
        thumbnail: '',
        deliveryStatus: 'PREPARATION',
        fabricFile: {},
        purchaseStatus: null,
        purchasePrice: null,
        assetRemaining: 0,
        assetAmount: 0,
        billingPlan: 'PAY_AS_YOU_GO',
        isAdditionalColor: null
      },
      messages: {
        errors: {
          unknown: '予期しないエラーが発生しました。',
          forbidden: 'このユーザーはアクセス権限がありませんから、システム管理者に連絡してください。',
          token: 'トークンは問題があります。'
        }
      },
      comsitionTableExpanded: false,
      prevRoute: undefined
    }
  },
  computed: {
    downloadUrl() {
      return apiConfig.downloadUrl
    },
    customerId(): string {
      if (!this.$auth.user!!['https://stylem-site/claims/appMetadata'].customer_id) {
        return ''
      }
      return this.$auth.user!!['https://stylem-site/claims/appMetadata'].customer_id
    },
    backLink(): any {
      let label = '品目検索'
      let routeName = 'Item-Search'
      let href = '/Item/Search'
      if (this.prevRoute && this.prevRoute.name === 'Item-DownloadHistory-List') {
        label = '3D画像ダウンロード履歴一覧'
        routeName = 'Item-DownloadHistory-List'
        href = '/DownloadHistory/List'
      }
      return {
        label,
        routeName,
        href
      }
    }
  },
  async mounted() {
    await this.fetchItem()
  },
  methods: {
    handleError(err: any) {
      const snackbarProps: SnackbarProps = {
        display: this.messages.errors.unknown,
        show: true,
        color: 'danger',
      }
      if (err.request && err.request.responseURL.includes('digifab.stylem.co.jp')) {
        if (err.request.status === 403) {
          snackbarProps.display = this.messages.errors.forbidden
        } else if (err.request.status === 401) {
          snackbarProps.display = this.messages.errors.token
        }
      }
      this.$eventbus.$emit('showSnackbar', snackbarProps)
    },
    handlePageError(err: any) {
      const snackbarProps: SnackbarProps = {
        display: this.messages.errors.unknown,
        show: true,
        color: 'danger',
      }
      const returnPage = {
        msg: `${this.backLink.label}に戻る`,
        name: this.backLink.routeName,
        url: this.backLink.href
      }
      if (err.request && err.request.responseURL.includes('digifab.stylem.co.jp')) {
        if (err.request.status === 404) {
          this.$router.push(
            {
              name: 'Item-Detail-404',
              params: {
                id: this.$route.params.id,
                detail: {
                  msg: '指定品目が見つかれません。',
                  returnPage: returnPage
                }
              } as any
            }
          )
          return
        }
        if (err.request.status === 403) {
          snackbarProps.display = this.messages.errors.forbidden
        } else if (err.request.status === 401) {
          snackbarProps.display = this.messages.errors.token
        }
      }
      this.$router.push(
        {
          name: 'Item-Detail-Error',
          params: {
            id: this.$route.params.id,
            detail: {
              msg: 'エラー',
              returnPage: returnPage
            } as any
          }
        }
      )
      this.$eventbus.$emit('showSnackbar', snackbarProps)
    },
    async fetchItem() {
      try {
        this.$eventbus.$emit('pageLoading', true)
        const params = {
          color: this.$route.query.color
        }
        const { data: fabricData }: AxiosResponse = await this.$axios.get(
          `fabric/${this.$route.params.id}`,
          {
            params: params
          }
        )
        const { data: customerData }: AxiosResponse = await this.$axios.get(
          `customer/${this.customerId}`,
          {
            params: params
          }
        )
        this.item = {
          itemCode: fabricData.item_code,
          name: fabricData.name,
          composition: fabricData.composition,
          description: fabricData.description,
          color: fabricData.color,
          defaultColor: fabricData.default_color,
          section: fabricData.section,
          size: fabricData.size,
          weight: fabricData.weight,
          fabricType: fabricData.fabric_type,
          pattern: fabricData.pattern ? fabricData.pattern : {
            type: ''
          },
          feeling: fabricData.feeling,
          appearance: fabricData.appearance,
          item: fabricData.item,
          function: fabricData.function,
          sustainable: fabricData.sustainable,
          deliveryStatus: fabricData.delivery_status,
          thumbnail: fabricData.thumbnail,
          fabricFile: {
            u3ma: fabricData.fabric_files ? fabricData.fabric_files.u3ma : undefined,
            zfab: fabricData.fabric_files ? fabricData.fabric_files.zfab : undefined,
            texture: fabricData.fabric_files ? fabricData.fabric_files.texture : undefined
          },
          purchaseStatus: fabricData.purchase_status,
          purchasePrice: fabricData.purchase_price,
          assetRemaining: customerData.asset_remaining,
          assetAmount: customerData.asset_amount,
          billingPlan: customerData.billing_plan,
          isAdditionalColor: fabricData.is_additional_color
        }
      } catch (e: any) {
        this.handlePageError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async download3D(itemCode: string, fileType: string, color: string, defaultColor: boolean, price: number | null) {
      try {
        this.$eventbus.$emit('pageLoading', true)
        let tokenParams = {}
        const downloadParams = new URLSearchParams()
        if (!defaultColor) {
          tokenParams = {
            color: color
          }
        }
        const {
          data: { token },
        }: AxiosResponse = await this.$axios.get(
          `fabric/${itemCode}/${fileType}/token`,
          {
            params: tokenParams
          }
        )
        downloadParams.set('token', token)
        if (!defaultColor) {
          downloadParams.set('color', color)
        }
        if (price !== null && price !== undefined) {
          downloadParams.set('unit_price', `${price}`)
        }
        window.open(
          `${apiConfig.baseUrl}/fabric/${itemCode}/${fileType}/download?${downloadParams.toString()}`,
          '_blank'
        )
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async showdownload3DConfirm(itemCode: string, fileType: string, color: string, defaultColor: boolean, downloaded: boolean, price: number | null) {
      if (downloaded) {
        this.download3D(itemCode, fileType, color, defaultColor, price)
        return
      }
      this.$bvModal.msgBoxConfirm('ダウンロードを実行してよろしいですか？', {
        title: '3D画像ダウンロード',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: 'OK',
        cancelTitle: 'キャンせル',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then((value: boolean) => {
        if (value) {
          this.download3D(itemCode, fileType, color, defaultColor, price)
        }
      })
    }
  },
  metaInfo: {
    title: '品目詳細'
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<any>) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
})
