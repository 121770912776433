import { Axios, AxiosRequestConfig } from 'axios'

import { getInstance } from '@/assets/auth0'

export function jwtInterceptor(axios: Axios) {
  axios.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const auth: any = getInstance()
    if (auth.$data.isAuthenticated) {
      const token = await auth.getTokenSilently()
      config.headers!!.Authorization = `Bearer ${token}`
    }
    return config
  })
}

export default {
  jwtInterceptor
}
