



























import Vue from 'vue'
import Navbar from '@/components/common/Navbar.vue'
import NavSidebar from '@/components/common/NavSidebar.vue'
import FooterTop from '@/components/common/FooterTop.vue'
import FooterBottom from '@/components/common/FooterBottom.vue'

import { INavSidebarItem } from '@/assets/interfaces/component/NavSidebar'

interface Data {
  headerItem: Array<INavSidebarItem>
}

export default Vue.extend({
  name: 'Default',
  components: {
    Navbar,
    NavSidebar,
    FooterBottom,
    FooterTop
  },
  data() : Data {
    return {
      headerItem: [
        {
          name: 'index',
          icon: 'house-door-fill',
          label: 'Home',
          href: '/',
          items: []
        },
        {
          name: 'item',
          icon: 'person-square',
          label: '品目データ',
          href: '/Item/Search',
          items: [
            {
              name: 'item-search',
              icon: '',
              label: '品目検索',
              href: '/Item/Search',
            },
            {
              name: 'item-download-history-list',
              icon: '',
              label: '3D画像ダウンロード履歴一覧',
              href: '/DownloadHistory/List',
            }
          ]
        }
      ]
    }
  },
  computed: {
    billingPlan(): string {
      if (!this.$auth.user!!['https://stylem-site/claims/appMetadata'].billing_plan) {
        return 'PAY_AS_YOU_GO'
      }
      return this.$auth.user!!['https://stylem-site/claims/appMetadata'].billing_plan
    }
  }
})
