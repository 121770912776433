






























































import Vue from 'vue'

export default Vue.extend({
  name: 'CommercialLaw',
  metaInfo: {
    title: '特定商取引法'
  }
})
