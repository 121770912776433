










import Vue from 'vue'

import LoadingWrap from '@/components/common/LoadingWrap.vue'
import Snackbar from '@/components/common/Snackbar.vue'

export default Vue.extend({
  name: 'App',
  components: {
    LoadingWrap,
    Snackbar
  },
  metaInfo: {
    title: 'Stylem Digital Fabric Library',
    titleTemplate: '%s | Stylem Digital Fabric Library'
  }
})
