







import Vue from 'vue'

interface Data {
  pageLoading: boolean
}

export default Vue.extend({
  name: 'LoadingWrap',
  data() : Data {
    return {
      pageLoading: false
    }
  },
  mounted() {
    this.$eventbus.$on('pageLoading', (val: boolean) => {
      this.pageLoading = val
    })
  },
  destroyed() {
    this.$eventbus.$off('pageLoading')
  }
})
