






























































import Vue from 'vue'

export default Vue.extend({
  name: 'PrivicyPolicy',
  metaInfo: {
    title: 'プライバシーポリシー'
  }
})
