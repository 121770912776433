import Vue from 'vue'
import VueRouter, {
  NavigationGuardNext,
  RawLocation,
  Route,
  RouteConfig
} from 'vue-router'
import Home from '@/views/Home.vue'
import Error from '@/views/Error.vue'
import Default from '@/layouts/Default.vue'
import ItemSearch from '@/views/Item/Search.vue'
import ItemDetail from '@/views/Item/Detail.vue'
import LoginRedirect from '@/views/LoginRedirect.vue'
import LogoutRedirect from '@/views/LogoutRedirect.vue'
import { ErrorDetail } from '@/assets/interfaces/component/Error'
import DownloadHistoryList from '@/views/DownloadHistory/List.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import CommercialLaw from '@/views/CommercialLaw.vue'

const contentElementId = 'content-wrapper'

interface IElementPosition {
  [key: string]: {
    scrollTop: number
  }
}

export const positionElement: IElementPosition = {}

Vue.use(VueRouter)

const { isNavigationFailure, NavigationFailureType } = VueRouter
const originalPush = VueRouter.prototype.push

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: Default,
      protect: false
    }
  },
  {
    path: '/callback/login',
    name: 'CallbackLogin',
    component: LoginRedirect,
    meta: {
      protect: false
    }
  },
  {
    path: '/callback/logout',
    name: 'CallbackLogout',
    component: LogoutRedirect,
    meta: {
      protect: false
    }
  },
  {
    path: '/Item/Search',
    name: 'Item-Search',
    component: ItemSearch,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '/Item/:id',
    name: 'Item-Detail',
    component: ItemDetail,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '/Item/:id/404',
    name: 'Item-Detail-404',
    component: Error,
    meta: {
      layout: Default,
      protect: true
    },
    props: (route: any): { detail: ErrorDetail } => {
      return {
        detail: {
          msg: '指定品目が見つかれません。',
          returnPage: {
            msg: '品目検索に戻る',
            name: 'Item-Search',
            url: '/Item/Search'
          }
        },
        ...route.params
      }
    }
  },
  {
    path: '/Item/:id/error',
    name: 'Item-Detail-Error',
    component: Error,
    meta: {
      layout: Default,
      protect: true
    },
    props: (route: any): { detail: ErrorDetail } => {
      return {
        detail: {
          msg: 'エラー',
          returnPage: {
            msg: '品目検索に戻る',
            name: 'Item-Search',
            url: '/Item/Search'
          }
        },
        ...route.params
      }
    }
  },
  {
    path: '/DownloadHistory/List',
    name: 'Item-DownloadHistory-List',
    component: DownloadHistoryList,
    meta: {
      layout: Default,
      protect: true
    }
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      layout: Default,
      protect: false
    }
  },
  {
    path: '/CommercialLaw',
    name: 'CommercialLaw',
    component: CommercialLaw,
    meta: {
      layout: Default,
      protect: false
    }
  },
  {
    path: '*',
    name: 'NotFound',
    component: Error,
    meta: {
      layout: Default,
      protect: true
    },
    props: {
      detail: {
        msg: 'Page Not Found',
        returnPage: {
          msg: 'ホームに戻る',
          name: 'Home',
          url: '/'
        }
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to: Route, from: Route, savePosition: any) {
    let stateKey: string | undefined
    if (window.history.state) {
      stateKey = window.history.state.key
    }
    const el = window.document.getElementById(contentElementId)
    if (el) {
      if (stateKey && positionElement[stateKey]) {
        el.scrollTop = positionElement[stateKey].scrollTop
      } else {
        el.scrollTop = 0
      }
    }
  }
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (window.history.state) {
    const stateKey = window.history.state.key
    const el = window.document.getElementById(contentElementId)
    if (el) {
      positionElement[stateKey] = {
        scrollTop: el.scrollTop + el.clientTop
      }
    }
  }
  next()
})

async function doPush(location: RawLocation): Promise<any> {
  try {
    const result = await (originalPush as (location: RawLocation) => Promise<Route>).call(router, location)
    return result
  } catch (e: any) {
    if (!isNavigationFailure(e, NavigationFailureType.duplicated)) {
      throw e
    }
  }
}

function push(location: RawLocation, onComplete?: any, onAbort?: any): void
function push(location: RawLocation): Promise<any>
function push(location: RawLocation, onComplete?: any, onAbort?: any): Promise<any> | void{
  if (onComplete || onAbort) {
    (originalPush as (location: RawLocation, onComplete?: any, onAbort?: any) => void).call(router, location, onComplete, onAbort)
  } else {
    return doPush(location)
  }
}

VueRouter.prototype.push = push

export default router
