


































































































































































import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { NavigationGuardNext, Route } from 'vue-router'

import apiConfig from '@/assets/consistants/apiConfig'
import { TableField } from '@/assets/interfaces/common'
import {
  IDownload3DExport,
  IDownload3DTable,
  BillingPlan,
  BillingType
} from '@/assets/interfaces/Download3D'
import { SnackbarProps } from '@/assets/interfaces/component/Snackbar'
import { appendLeadingZeroes, clearEmpties, exportToCsv } from '@/assets/utils'

interface Data {
  tableProps: {
    headers: Array<TableField>
    items: Array<IDownload3DTable>
    perPage: number
    noDataText: string
    tokenStack: Array<string | null>
    nextToken: string | null
    tableLoading: boolean
    currentToken: string | null
  }
  messages: {
    errors: {
      unknown: string
      forbidden: string
      token: string
      date: string
    }
    success: {
    }
  }
  modalLoading: boolean
  date: {
    from: string,
    range: Array<string>
  }
  selectedDate: {
    start: string
    end: string
  },
  prevRoute?: Route
}

export default Vue.extend({
  name: 'DownloadHistoryList',
  data(): Data {
    return {
      tableProps: {
        headers: [
          {
            key: 'itemCode',
            label: '品番',
            sortable: false
          },
          {
            key: 'color',
            label: '色番',
            sortable: false
          },
          {
            key: 'name',
            label: 'スタイル名',
            sortable: false
          },
          {
            key: 'fileType',
            label: '３Dデータの種類',
            sortable: false
          },
          {
            key: 'thumbnail',
            label: 'サムネイル画像',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'downloadAt',
            label: 'ダウンロード日時',
            sortable: false
          },
          {
            key: 'customerName',
            label: '顧客名',
            sortable: false
          },
          {
            key: 'divisionName',
            label: '部門',
            sortable: false
          },
          {
            key: 'userName',
            label: 'ダウンロードユーザー名',
            sortable: false
          },
          {
            key: 'isBillingTarget',
            label: '課金対象レコードか',
            class: 'text-center',
            sortable: false
          },
          {
            key: 'actions',
            label: 'アクション',
            class: 'text-center',
            sortable: false
          }
        ],
        items: [],
        perPage: 10,
        noDataText: 'データが見つかりません',
        tableLoading: false,
        tokenStack: [],
        nextToken: null,
        currentToken: null
      },
      messages: {
        errors: {
          unknown: '予期しないエラーが発生しました。',
          forbidden: 'このユーザーはアクセス権限がありませんから、システム管理者に連絡してください。',
          token: 'トークンは問題があります。',
          date: '終了日は開始日以降の日付を指定してください。'
        },
        success: {
        }
      },
      modalLoading: false,
      date: {
        from: '2021-12-01',
        range: []
      },
      selectedDate: {
        start: '',
        end: ''
      },
      prevRoute: undefined
    }
  },
  computed: {
    targetMonth() {
      const currentMonth = new Date()
      currentMonth.setDate(1)
      const nextMonth = new Date()
      nextMonth.setDate(1)
      nextMonth.setMonth(nextMonth.getMonth() + 1)
      return {
        currentMonth,
        nextMonth
      }
    },
    downloadUrl() {
      return apiConfig.downloadUrl
    }
  },
  methods: {
    handleError(err: any) {
      const snackbarProps: SnackbarProps = {
        display: this.messages.errors.unknown,
        show: true,
        color: 'danger'
      }
      if (err.request && err.request.responseURL.includes('digifab.stylem.co.jp')) {
        if (err.request.status === 403) {
          snackbarProps.display = this.messages.errors.forbidden
        } else if (err.request.status === 401) {
          snackbarProps.display = this.messages.errors.token
        }
      }
      this.$eventbus.$emit('showSnackbar', snackbarProps)
    },
    initPage() {
      this.selectedDate.start = `${this.targetMonth.currentMonth.getFullYear()}-${appendLeadingZeroes(this.targetMonth.currentMonth.getMonth() + 1)}-01`
      this.selectedDate.end = `${this.targetMonth.nextMonth.getFullYear()}-${appendLeadingZeroes(this.targetMonth.nextMonth.getMonth() + 1)}-01`
      this.fetchList()
    },
    async fetchList() {
      try {
        this.tableProps.tableLoading = true
        const end = new Date(this.selectedDate.end)
        end.setDate(end.getDate() + 1)
        const params = {
          limit: this.tableProps.perPage,
          next_token: this.tableProps.nextToken,
          start: this.selectedDate.start,
          end: `${end.getFullYear()}-${appendLeadingZeroes(end.getMonth() + 1)}-${appendLeadingZeroes(end.getDate())}`
        }
        clearEmpties(params)
        const { data }: AxiosResponse = await this.$axios.get(
          'download-history',
          {
            params: params
          }
        )
        this.tableProps.items = data.history.map((item: any): IDownload3DTable => {
          return {
            itemCode: item.item_code,
            color: item.color,
            name: item.name,
            fileType: item.file_type,
            thumbnail: item.thumbnail,
            downloadAt: item.download_at,
            customerName: item.customer_name,
            divisionName: item.division_name,
            userName: item.user_name,
            isBillingTarget: item.is_billing_target,
          }
        })
        this.tableProps.currentToken = this.tableProps.nextToken
        this.tableProps.nextToken = data.next_token
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.tableProps.tableLoading = false
      }
    },
    async previousPage() {
      if (this.tableProps.tokenStack.length > 0) {
        this.tableProps.nextToken = this.tableProps.tokenStack.pop()!!
        await this.fetchList()
      }
    },
    async nextPage() {
      this.tableProps.tokenStack.push(this.tableProps.currentToken)
      await this.fetchList()
    },
    async downloadCsv() {
      try {
        this.$eventbus.$emit('pageLoading', true)
        let historyList: Array<IDownload3DExport> = []
        let firstInit = true
        const end = new Date(this.selectedDate.end)
        end.setDate(end.getDate() + 1)
        const params = {
          limit: 50,
          next_token: null,
          start: this.selectedDate.start,
          end: `${end.getFullYear()}-${appendLeadingZeroes(end.getMonth() + 1)}-${appendLeadingZeroes(end.getDate())}`
        }
        /* eslint-disable no-await-in-loop */
        while (firstInit || params.next_token) {
          clearEmpties(params)
          const { data }: AxiosResponse = await this.$axios.get(
            'download-history',
            {
              params: params
            }
          )
          historyList = [
            ...historyList,
            ...data.history.map((item: any): IDownload3DExport => {
              return {
                itemCode: item.item_code,
                color: item.color,
                name: item.name,
                fileType: item.file_type,
                thumbnail: item.thumbnail,
                downloadAt: item.download_at,
                customerName: item.customer_name,
                divisionName: item.division_name,
                userName: item.user_name,
                amount: item.amount,
                isBillingTarget: item.is_billing_target,
                billingType: (BillingType as any)[item.billing_type] ? (BillingType as any)[item.billing_type] : item.billing_type,
                billingPlan: (BillingPlan as any)[item.billing_plan] ? (BillingPlan as any)[item.billing_plan] : item.billing_plan
              }
            })
          ]
          params.next_token = data.next_token
          firstInit = false
        }
        /* eslint-disable no-await-in-loop */
        exportToCsv(`download_history_${this.selectedDate.start}-${this.selectedDate.end}.csv`, [
          '品番',
          '色番',
          'スタイル名',
          '３Dデータの種類',
          'サムネイル画像',
          'ダウンロード日時',
          '顧客名',
          '部門',
          'ダウンロードユーザー名',
          '金額',
          '課金対象レコードか',
          '課金種別',
          'プラン'
        ], historyList.map((value: IDownload3DExport) => Object.values(value)))
      } catch (e: any) {
        this.handleError(e)
      } finally {
        this.$eventbus.$emit('pageLoading', false)
      }
    },
    async submitDateRange() {
      if (Date.parse(this.selectedDate.start) > Date.parse(this.selectedDate.end)) {
        const snackbarProps: SnackbarProps = {
          display: this.messages.errors.date,
          show: true,
          color: 'warning'
        }
        this.$eventbus.$emit('showSnackbar', snackbarProps)
        return
      }
      this.clearPagination()
      await this.fetchList()
    },
    clearPagination() {
      this.tableProps.tokenStack = []
      this.tableProps.nextToken = null
      this.tableProps.currentToken = null
    }
  },
  async mounted() {
    if (this.prevRoute?.name?.includes('Item-Detail')) {
      const savedPagination = window.sessionStorage.getItem('download_history_pagination')
      if (savedPagination) {
        const parsedPagination = JSON.parse(savedPagination)
        this.tableProps.tokenStack = parsedPagination.tokenStack
        this.tableProps.nextToken = parsedPagination.currentToken
        this.selectedDate.start = parsedPagination.start
        this.selectedDate.end = parsedPagination.end
        await this.fetchList()
      } else {
        await this.initPage()
      }
    } else {
      await this.initPage()
    }
    window.sessionStorage.removeItem('download_history_pagination')
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    window.sessionStorage.setItem('download_history_pagination', JSON.stringify(
      {
        tokenStack: this.tableProps.tokenStack,
        currentToken: this.tableProps.currentToken,
        start: this.selectedDate.start,
        end: this.selectedDate.end
      }
    ))
    next()
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<any>) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  metaInfo: {
    title: '3D画像ダウンロード履歴一覧'
  }
})
