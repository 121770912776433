import axios, { AxiosInstance } from 'axios'
import Vue from 'vue'

import apiConfig from '@/assets/consistants/apiConfig'

function createInstance(baseURL: string): AxiosInstance {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const instance: AxiosInstance = createInstance(apiConfig.baseUrl)

export const getInstance = () : AxiosInstance => instance

export default {
  install() {
    Vue.prototype.$axios = instance
  }
}
