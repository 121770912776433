import Vue from 'vue'

Vue.filter('empty', (value: any) => {
  if (!value) {
    return '-'
  }
  return value
})

Vue.filter('joinComma', (value: string[]) => {
  if (!value || value.length === 0) {
    return '-'
  }
  return value.join('、')
})

Vue.filter('billingPlan', (value: string) => {
  if (!value) {
    return ''
  }
  switch (value) {
    case 'PAY_AS_YOU_GO':
      return '都度課金'
    case 'TX':
      return 'TXプラン'
    case 'PREMIUM':
      return 'プレミアムプラン'
    default:
      return value
  }
})

export default {}
