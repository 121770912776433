










































































































































































































































































































































































import Vue from 'vue'
import { NavigationGuardNext, Route } from 'vue-router'
import VueSlider from 'vue-slider-component'
import algoliasearch, { SearchClient } from 'algoliasearch/lite'
import { Router, StateMapping, UiState } from 'instantsearch.js/es/types'
import { LabelValuePair } from '@/assets/interfaces/common'
import apiConfig from '@/assets/consistants/apiConfig'
import CompositionTable from '@/components/item/detail/CompositionTable.vue'

import 'instantsearch.css/themes/satellite-min.css'
import 'vue-slider-component/theme/antd.css'

interface Data {
  searchClient: SearchClient
  indexName: string
  viewAs: {
    grid: boolean
    list: boolean
  }
  sortBy: {
    items: Array<LabelValuePair>
  }
  searchBar: {
    focus: boolean
    focusIn: boolean
  }
  showFilter: boolean
  routing: {
    router: Router
    stateMapping: StateMapping
  }
  prevRoute?: Route
}

export default Vue.extend({
  name: 'ItemSearch',
  components: {
    VueSlider,
    CompositionTable
  },
  data(): Data {
    const indexName = `${apiConfig.algolia.indexName}_item_code_asc`
    const vueRouter = this.$router
    const createPopStateCallback = (cb: any, read: any) => (event: PopStateEvent) => {
      const routeState = event.state
      // at initial load, the state is read from the URL without
      // update. Therefore the state object is not there. In this
      // case we fallback and read the URL.
      if (!routeState) {
        cb(read())
      } else {
        cb(routeState)
      }
    }
    let onPopState: any
    return {
      searchClient: algoliasearch(
        apiConfig.algolia.applicationId,
        apiConfig.algolia.apiKey
      ),
      indexName,
      viewAs: vueRouter.currentRoute.query.view_as === 'list' ? {
        grid: false,
        list: true
      } : {
        grid: true,
        list: false
      },
      sortBy: {
        items: [
          {
            value: `${apiConfig.algolia.indexName}_item_code_asc`,
            label: '品番（昇順）'
          },
          {
            value: `${apiConfig.algolia.indexName}_item_code_desc`,
            label: '品番（降順）'
          },
          {
            value: `${apiConfig.algolia.indexName}_name_asc`,
            label: 'スタイル名（昇順）'
          },
          {
            value: `${apiConfig.algolia.indexName}_name_desc`,
            label: 'スタイル名（降順）'
          }
        ]
      },
      searchBar: {
        focus: false,
        focusIn: false
      },
      showFilter: true,
      routing: {
        router: {
          read(): any {
            return vueRouter.currentRoute.query
          },
          write(routeState?: any) {
            if (vueRouter.currentRoute.name === 'Item-Search') {
              const currentQuery = this.read()
              vueRouter.push({
                query: {
                  ...currentQuery,
                  ...routeState
                }
              })
            }
          },
          createURL(routeState: any) {
            return vueRouter.resolve({
              query: routeState,
            }).href
          },
          onUpdate(cb) {
            if (!onPopState) {
              onPopState = createPopStateCallback(cb, this.read)
            }
            window.addEventListener('popstate', onPopState)
          },
          dispose() {
            window.removeEventListener('popstate', onPopState)
          }
        },
        stateMapping: {
          stateToRoute(uiState: UiState): any {
            const createJoinRefinementList = (value?: {[attribute: string]: string[]}) => (key: string) => {
              if (!value) {
                return undefined
              }
              return value[key] ? value[key].join('~') : undefined
            }
            const joinRefinementList = createJoinRefinementList(uiState[indexName].refinementList)
            const formatSortBy = (sortBy?: string) => {
              if (!sortBy) {
                return undefined
              }
              return sortBy.slice(apiConfig.algolia.indexName.length + 1)
            }
            return {
              weight: uiState[indexName].range && uiState[indexName].range?.weight,
              composition_item: joinRefinementList('composition_items'),
              downloadable_file_type: joinRefinementList('downloadable_file_types'),
              fabric_type: joinRefinementList('fabric_type'),
              pattern: joinRefinementList('pattern'),
              function: joinRefinementList('function'),
              sustainable: joinRefinementList('sustainable'),
              feeling: joinRefinementList('feeling'),
              appearance: joinRefinementList('appearance'),
              item: joinRefinementList('item'),
              section: joinRefinementList('section'),
              sort_by: formatSortBy(uiState[indexName].sortBy),
              query: uiState[indexName].query,
              page: uiState[indexName].page
            }
          },
          routeToState(routeState: UiState): any {
            const state = routeState as {[attribute: string]: string}
            const createSplitRefinementList = (value?: {[attribute: string]: string}) => (key: string) => {
              if (!value) {
                return undefined
              }
              return value[key] ? value[key].split('~') : undefined
            }
            const splitRefinementList = createSplitRefinementList(state)
            return {
              [indexName]: {
                range: {
                  weight: state.weight
                },
                refinementList: {
                  composition_items: splitRefinementList('composition_item'),
                  downloadable_file_types: splitRefinementList('downloadable_file_type'),
                  fabric_type: splitRefinementList('fabric_type'),
                  pattern: splitRefinementList('pattern'),
                  function: splitRefinementList('function'),
                  sustainable: splitRefinementList('sustainable'),
                  feeling: splitRefinementList('feeling'),
                  appearance: splitRefinementList('appearance'),
                  item: splitRefinementList('item'),
                  section: splitRefinementList('section')
                },
                sortBy: state.sort_by && `${apiConfig.algolia.indexName}_${state.sort_by}`,
                query: state.query,
                page: state.page
              }
            }
          }
        }
      },
      prevRoute: undefined
    }
  },
  computed: {
    downloadUrl(): string {
      return apiConfig.downloadUrl
    },
    itemCodeRelation(): boolean {
      if (!this.$auth.user!!['https://stylem-site/claims/appMetadata'].sku_relation) {
        return true
      }
      return this.$auth.user!!['https://stylem-site/claims/appMetadata'].sku_relation === 'ITEM_CODE_AND_COLOR'
    },
    filters(): string {
      const filters: string[] = [
        'delivery_status:AVAILABLE'
      ]
      if (!this.itemCodeRelation) {
        filters.push('default_color:true')
      }
      return filters.join(' AND ')
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    if (this.prevRoute?.name?.includes('Item-Detail')) {
      const savedQuery = window.sessionStorage.getItem('item_search_query')
      if (savedQuery) {
        const parsedQuery = JSON.parse(savedQuery)
        this.$router.push({
          query: parsedQuery
        })
        if (parsedQuery.view_as === 'list') {
          this.viewAs = {
            grid: false,
            list: true
          }
        }
      }
    }
    window.sessionStorage.removeItem('item_search_query')
  },
  beforeDestroy() {
    window.addEventListener('resize', this.handleResize)
  },
  watch: {
    viewAs: {
      handler(value: any) {
        const currentQuery = this.$router.currentRoute.query
        const viewAs = value.list ? 'list' : undefined
        this.$router.push({
          query: {
            ...currentQuery,
            view_as: viewAs
          }
        })
      },
      deep: true
    }
  },
  methods: {
    toValue(value: any, range: any) {
      return [
        value.min ? value.min : range.min,
        value.max ? value.max : range.max
      ]
    },
    changeViewAs(method: string) {
      this.viewAs = {
        grid: method === 'grid',
        list: method === 'list'
      }
    },
    handleResize() {
      if (window.innerHeight >= 480) {
        this.showFilter = true
      } else {
        this.showFilter = false
      }
    },
    transformItems(items: any) {
      return items.map((item: any) => {
        const mapLabel = (attribute: string) => {
          const labelName: { [key: string]: string } = {
            downloadable_file_types: 'File Type',
            fabric_type: 'Fabric Type',
            pattern: 'Pattern',
            composition_items: 'Composition',
            weight: 'Weight',
            function: 'Function',
            sustainable: 'Sustainable',
            feeling: 'Feeling',
            appearance: 'Appearance',
            item: 'Item',
            section: 'Section'
          }
          return labelName[attribute] ? labelName[attribute] : attribute
        }
        item.label = mapLabel(item.attribute)
        return item
      })
    },
    onPageChange() {
      this.$nextTick(() => {
        const contentWrapper = document.getElementById('content-wrapper')
        if (contentWrapper) {
          contentWrapper.scrollTo(
            {
              top: 0,
              left: 0,
              behavior: 'smooth'
            }
          )
        }
      })
    }
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    const currentQuery = this.$router.currentRoute.query
    window.sessionStorage.setItem('item_search_query', JSON.stringify(
      currentQuery
    ))
    next()
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<any>) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  metaInfo: {
    title: '品目検索'
  }
})
