


















































































































































































import Vue from 'vue'
import { IItem } from '@/assets/interfaces/Item'

export default Vue.extend({
  name: 'TextileDetail',
  props: {
    item: {
      type: Object as () => IItem & {
        assetRemaining: number
        assetAmount: number
        billingPlan: string
      },
      required: true
    },
    backLink: {
      type: Object as () => {
        label: string,
        routeName: string,
        href: string
      },
      default: () => ({
        label: '品目検索へ',
        href: '/Item/Search'
      })
    }
  },
  computed: {
    showDigitalFabric(): boolean {
      return !!this.item.fabricFile.u3ma || !!this.item.fabricFile.zfab
    },
    showTexture(): boolean {
      return !!this.item.fabricFile.texture
    },
    showDownload(): boolean {
      return this.showDigitalFabric || this.showTexture
    },
    dfPrice(): number | null {
      return this.item.purchasePrice ? this.item.purchasePrice.fabric! : null
    },
    texturePrice(): number | null {
      return this.item.purchasePrice ? this.item.purchasePrice.texture! : null
    },
    dfPurchaseStatus(): boolean {
      return this.item.purchaseStatus?.fabric === 'purchased'
    },
    texturePurchaseStatus(): boolean {
      return this.item.purchaseStatus?.texture === 'purchased'
    },
    dfDownloadDetail(): string[] {
      const detail: string[] = []
      const targetPlan = [
        'PAY_AS_YOU_GO',
        'TX',
        'PREMIUM'
      ]
      if (this.item.purchaseStatus?.fabric === 'purchased') {
        detail.push('再ダウンロード')
        detail.push('¥0')
      } else if (targetPlan.includes(this.item.billingPlan) && this.item.isAdditionalColor?.fabric) {
        detail.push('追加購入')
        detail.push(`¥${this.item.purchasePrice ? this.item.purchasePrice.fabric : 500}`)
      } else if (this.item.billingPlan === 'PREMIUM' && this.item.assetRemaining > 0) {
        detail.push('購入')
        detail.push(`¥${this.item.purchasePrice?.fabric}`)
        detail.push(`(残り ${this.item.assetRemaining}/${this.item.assetAmount}回)`)
      } else {
        detail.push('購入')
        detail.push(`¥${this.item.purchasePrice ? this.item.purchasePrice.fabric : 0}`)
      }
      return detail
    },
    textureDownloadDetail(): string[] {
      const detail: string[] = []
      const targetPlan = [
        'PAY_AS_YOU_GO',
        'TX',
        'PREMIUM'
      ]
      if (this.item.purchaseStatus?.texture === 'purchased') {
        detail.push('再ダウンロード')
        detail.push('¥0')
      } else if (targetPlan.includes(this.item.billingPlan) && this.item.isAdditionalColor?.texture) {
        detail.push('追加購入')
        detail.push(`¥${this.item.purchasePrice ? this.item.purchasePrice.texture : 500}`)
      } else if (this.item.billingPlan === 'TX' && this.item.assetRemaining > 0) {
        detail.push('購入')
        detail.push(`¥${this.item.purchasePrice?.texture}`)
        detail.push(`(残り ${this.item.assetRemaining}/${this.item.assetAmount}回)`)
      } else {
        detail.push('購入')
        detail.push(`¥${this.item.purchasePrice ? this.item.purchasePrice.texture : 0}`)
      }
      return detail
    }
  }
})
