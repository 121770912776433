













import Vue from 'vue'
import { ErrorDetail } from '@/assets/interfaces/component/Error'

export default Vue.extend({
  name: 'error',
  props: {
    detail: {
      type: Object as () => ErrorDetail,
      default: () => ({
        msg: '予期しないエラーが発生しました。',
        returnPage: {
          msg: 'ホームに戻る',
          name: 'Home',
          url: '/'
        }
      })
    }
  },
  methods: {
    backToReturnPage() {
      this.$router.push(
        {
          name: this.detail.returnPage.name
        }
      )
    }
  }
})
