














import Vue from 'vue'
import { INavSidebarItem } from '@/assets/interfaces/component/NavSidebar'

export default Vue.extend({
  name: 'NavSidebarSubItem',
  props: {
    item: {
      type: Object as () => INavSidebarItem,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick(e: any, href: string) {
      e.preventDefault()
      if (href !== '#') {
        this.$router.push(href)
      }
    }
  }
})
