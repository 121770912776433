






import Vue from 'vue'

export default Vue.extend({
  name: 'LogoutRedirect',
  mounted() {
    this.$router.push('/')
  }
})
