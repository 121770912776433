




















import Vue from 'vue'

export default Vue.extend({
  name: 'Home',
  computed: {
    loginErrorMsg() {
      if (window) {
        const errorDescription = window.sessionStorage.getItem('error_description')
        return errorDescription
      }
      return ''
    }
  },
  metaInfo: {
    title: 'Home'
  }
})
