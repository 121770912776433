









































import Vue from 'vue'
import { compositionDetail } from '@/assets/interfaces/Item'

export default Vue.extend({
  name: 'CompositionTable',
  computed: {
    composition() {
      return compositionDetail
    },
    compostionGroupNumber() {
      const groupNumber: { [key: number]: number } = {}
      let pastIndex: number | undefined
      let count = 0
      this.composition.forEach((value: any, index: number) => {
        if (Object.keys(value).length === 3) {
          if (pastIndex === undefined) {
            pastIndex = index
          } else {
            groupNumber[pastIndex] = count
            pastIndex = index
            count = 0
          }
        }
        count += this.composition[index].detail.length
      })
      if (pastIndex !== undefined) {
        groupNumber[pastIndex] = count
      }
      return groupNumber
    }
  }
})
